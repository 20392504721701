import React from 'react'

const Disclaimer = () => {
  return (
   <> 
     <div className="body_area grid_main">
     <div className='left_a'>
     <div className="prodct_deatils">
<p className="ft_18 roboto_medium d_blue">Disclaimer</p>
<p className="ft_18 roboto_medium mt_10">a. At Your Own Risk</p>
<p className="ft_14 robot_regular d_lblack mt_10">You acknowledge that your use of Buy N Sell is at your own risk, and the platform is provided "as is" and "as available," without any warranties, express or implied.</p>

<p className="ft_18 roboto_medium mt_10">b. Disclaimer of Warranties</p>
<p className="ft_18 robot_medium d_black mt_20">Buy N Sell disclaims all warranties, including but not limited to:</p>
<p className="ft_14 robot_regular d_lblack mt_10">
● Merchantability and fitness for a particular purpose.<br></br>
● Accuracy, reliability, or completeness of listings and content.<br></br>
● Continuous, uninterrupted, or error-free operation of the platform.
</p>
<p className="ft_18 roboto_medium mt_10">c. Limitation of Liability</p>
<p className="ft_18 robot_medium d_black mt_20">Buy N Sell is not liable for:</p>
<p className="ft_14 robot_regular d_lblack mt_10">● Errors, inaccuracies, or delays in listings.<br></br>
● Personal injury or property damage resulting from transactions.<br></br>
● Unauthorized access, hacking, or data breaches.<br></br>
● Bugs, viruses, malware, or harmful components on the platform.
</p>
<p className="ft_18 roboto_medium mt_10">d. Third-Party Products and Services</p>
<p className="ft_14 robot_regular d_lblack mt_10">● Buy N Sell does not endorse or guarantee any third-party products, services, or transactions. Users should exercise caution when exchanging contact details or conducting transactions.
</p>

<p className="ft_18 roboto_medium mt_10">e. Intermediary Role</p>
<p className="ft_14 robot_regular d_lblack mt_10">● Buy N Sell operates as an intermediary platform and does not control or verify the quality, legality, or authenticity of listed items. Users are advised to independently verify all information before making decisions.</p>

<p className="ft_18 roboto_medium mt_10">f. Grievances and Disputes</p>
<p className="ft_14 robot_regular d_lblack mt_10">● Any grievances regarding unfair trade practices or deficiencies in services should be directly addressed with the listing owner. Buy N Sell is not responsible for user actions, representations, or disputes.</p>
</div>

<div className="prodct_deatils">
<p className="ft_18 roboto_medium d_blue">Advertising Material</p>
<p className="ft_14 robot_regular d_lblack mt_10">Some parts of the Website may include advertising information, promotional material, or other content submitted to the Company by third parties. The responsibility for ensuring that submitted material complies with applicable laws rests solely with the party providing the information.</p>

<p className="ft_14 robot_regular d_lblack mt_10">Your interactions, including correspondence or business dealings, with advertisers on the Website are solely between you and the advertiser. This includes payment, delivery of goods or services, and any associated terms, conditions, warranties, or representations. The Company will not be responsible or liable for any claims, errors, omissions, inaccuracies in advertising material, or any loss or damage incurred as a result of such interactions.
</p>
<p className="ft_14 robot_regular d_lblack mt_10">The Company reserves the right to omit, suspend, or change the position of any advertising material submitted for insertion on the Website.</p>
</div>

<div className="prodct_deatils">
<p className="ft_18 roboto_medium d_blue">Limitation of Liability</p>
<p className="ft_14 robot_regular d_lblack mt_10">To the fullest extent permitted by law, you agree to indemnify and hold the Company harmless at all times from and against any and all liabilities, claims, demands, losses, damages, costs, and expenses that arise from or are related to any act, default, omission, or breach of warranty by you as outlined in these Terms. You further agree to indemnify and hold the Company harmless from any liability, actions, claims, demands, losses, damages, costs, and expenses that the Company may incur as a result of your actions, defaults, or omissions, whether negligent or otherwise, under these Terms, including any breach or non-compliance with these rules.</p>

<p className="ft_14 robot_regular d_lblack mt_10">The Company, including its management, will not be responsible for any loss, damage, or missed opportunity resulting from technical interruptions or any other issues beyond the Company’s control.</p>
<p className="ft_14 robot_regular d_lblack mt_10">To the maximum extent permitted by law, the Company shall not be held liable to you or any other entity, under any legal theory (whether in contract, tort, or otherwise), for any direct, compensatory, indirect, incidental, consequential, or punitive damages, including but not limited to lost profits, business opportunities, revenue, income, goodwill, use, data, or any other intangible losses, that arise out of or relate in any manner to your use of the Services.</p>

<p className="ft_14 robot_regular d_lblack mt_10">This includes any damages resulting from errors, inaccuracies, omissions, defects, security breaches, or any failure to perform on the part of the Services. Even if the Company has been advised of the possibility of such damages, it shall not be liable for such losses or damages.</p>
<p className="ft_14 robot_regular d_lblack mt_10">Some jurisdictions do not allow the exclusion or limitation of liability for incidental or consequential damages. In such cases, the above limitations may not apply to you to the extent prohibited by applicable law.
</p>

</div>


<div className="prodct_deatils">
<p className="ft_18 roboto_medium d_blue">International Use</p>
<p className="ft_14 robot_regular d_lblack mt_10">The Website is operated and controlled from India. The Company does not warrant that the Website is suitable or available for use in locations outside of Africa. Users who access the Website from other locations do so at their own risk and are responsible for compliance with local laws.
</p>
</div>
<div className="prodct_deatils">
<p className="ft_18 roboto_medium d_blue">Applicable Law</p>
<p className="ft_14 robot_regular d_lblack mt_10">This Agreement shall be governed by and construed in accordance with the laws of India. Any disputes arising out of or in connection with this Agreement shall be subject to the exclusive jurisdiction of the courts in New Delhi. However, the Company reserve the right to initiate legal proceedings against you in any court of competent jurisdiction, as we deem necessary.</p>
</div>

<div className="prodct_deatils">
<p className="ft_18 roboto_medium d_blue">Relationship between the parties</p>
<p className="ft_14 robot_regular d_lblack mt_10">The Terms and Conditions do not establish a partnership or agency relationship between you and the Company. You are not authorized to bind the Company in any capacity. If you are registering on the Website on behalf of a business entity, you affirm that you have the authority to legally bind the entity to this Agreement.
</p>
</div>
<div className="prodct_deatils">
<p className="ft_18 roboto_medium d_blue">Complaints and Dispute Resolution</p>
<p className="ft_14 robot_regular d_lblack mt_10">Most concerns can be solved quickly by contacting us at<a href="mailto:hello@mchamp.com"  >hello@mchamp.com</a></p>
</div>



<div className="prodct_deatils">
<p className="ft_18 roboto_medium d_blue">Questions About These Terms and Conditions</p>
<p className="ft_14 robot_regular d_lblack mt_10">If you have any questions about these terms or about the Services, please, Contact us to the following mail address:<a href="mailto:hello@mchamp.com"  >hello@mchamp.com</a></p>
</div>

      </div>
   <div className='right_a'></div>
</div>
   </>
  )
}

export default Disclaimer
