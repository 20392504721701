import React from 'react'

const Privacynpolicy = () => {
  return (
   <>
      <div className="body_area grid_main">
      <div className='left_a'>
  <div className="prodct_deatils">
<p className="ft_18 roboto_medium d_blue">Introduction</p>
<p className="ft_14 robot_regular d_lblack mt_10">This policy applies to users who visit or access our website https://bns.mchamp.xyz/ (“Website”)
or uses our services.</p>
<p className="ft_14 robot_regular d_lblack mt_10">TWe respect the privacy of it&#39;s users and is committed to protect it in all respects. We have drafted
this Privacy Policy to explain how we collect and use personal data and we are committed to
respect your privacy and to comply with applicable privacy laws. By using this Website, you
accept, without limitation or qualification, these terms and condition mentioned in Privacy Policy
and Terms &amp; Conditions. The Website may contain links to other companies&#39; websites and
services that have privacy policies of their own. We shall not be responsible for the privacy
practices of others. As you review our policy, keep in mind that it applies to all our brands,
products and services, websites, mobile apps, mobile sites or other online platform or
applications notwithstanding, such brand, product and service do or do not have a separate
privacy policy or that is linked to this policy (&quot;Our Services&quot; or &quot;Services.&quot;) If you do not agree
to the terms of this Privacy policy, please do not use this Website.</p>
<p className="ft_14 robot_regular d_lblack mt_10">You must be 18 years of age or older to utilize the Website in any capacity. By accessing the
Content and/or the Website, you affirm that you are at least 18 (eighteen) years of age and that
you possess the legal right and capacity to use the Website</p>
</div>

<div className="prodct_deatils">
<p className="ft_18 roboto_medium d_blue">Who are we?</p>
<p className="ft_14 robot_regular d_lblack mt_10">mChamp Entertainment Pvt. Ltd , is a company that is incorporated in India under the
Companies Act 1956. having its registered office at at I- 9, LGF, LAJPAT NAGAR-3, NEW
DELHI- 110024 India (hereinafter referred to as “We” or “The Company”).</p>
</div>

<div className="prodct_deatils">
<p className="ft_18 roboto_medium d_blue">What you need to do and your confirmation to us?</p>
<p className="ft_14 robot_regular d_lblack mt_10">Please read this privacy policy carefully to understand how we handle your personal information.
By engaging with us in the ways set out in this privacy policy, including downloading, accessing
and using any of our Website, you confirm that you have read and understood the entirety of this
privacy policy, as it applies to you.</p>
</div>

<div className="prodct_deatils">
<p className="ft_18 roboto_medium d_blue">What kinds of information do we collect?</p>
<p className="ft_14 robot_regular d_lblack mt_10">Depending on the services you request, we collect information only when you choose to provide
it. Our primary goal in collecting information is to deliver services that best meet your needs.
Below is a detailed explanation of the information we handle:</p>
<p className="ft_18 roboto_medium mt_10">1. Personal Information Provided by Users</p>
<p className="ft_18 roboto_medium mt_10">● Sellers:</p>
<p className="ft_14 robot_regular d_lblack mt_10">Sellers must provide the following details when listing products for sale:</p>


<p className="ft_14 robot_regular d_lblack mt_10">● Name</p>
<p className="ft_14 robot_regular d_lblack mt_10">● Email Address</p>
<p className="ft_14 robot_regular d_lblack mt_10">● Phone Number</p>
<p className="ft_14 robot_regular d_lblack mt_10">● Address</p>
<p className="ft_14 robot_regular d_lblack mt_10">● Product details (images, description, and price)</p>
<p className="ft_18 roboto_medium mt_10">● Buyers:</p>

<p className="ft_14 robot_regular d_lblack mt_10">Buyers can view product images and descriptions posted by sellers. To access
seller contact information (Name, Email Address, Phone Number, and Address),
buyers must pay prescribed fee.</p>
<p className="ft_18 roboto_medium mt_10">2. Device and Location Information</p>

<p className="ft_14 robot_regular d_lblack mt_10">We collect your device’s IP address to determine your approximate location. Location
data is used to enhance functionality, such as displaying relevant ads and connecting
buyers and sellers within specific regions.</p>

<p className="ft_18 roboto_medium mt_10">3. Digital Wallet Address:</p>
<p className="ft_14 robot_regular d_lblack mt_10">We use digital wallet authentication as a login method for accessing our
platform. When you choose to log in using your digital wallet, we collect and
store your wallet address to verify your identity, manage account access, and
enhance security.</p>

<p className="ft_14 robot_regular d_lblack mt_10">● Authentication &amp; Security:</p>
<p className="ft_14 robot_regular d_lblack mt_10">Your digital wallet serves as a unique identifier for
logging in. We do not access your private keys, seed phrases, or control your
funds.</p>
<p className="ft_14 robot_regular d_lblack mt_10">● Data Usage:</p>
<p className="ft_14 robot_regular d_lblack mt_10">Your wallet address may be used for account verification, fraud
prevention, and compliance with security and legal requirements.</p>

<p className="ft_14 robot_regular d_lblack mt_10">● Third-Party Sharing:</p>
<p className="ft_14 robot_regular d_lblack mt_10">We do not sell or share your wallet address with
unauthorized third parties. However, if required by law, we may disclose it to
regulatory authorities or compliance partners.</p>
<p className="ft_14 robot_regular d_lblack mt_10">● User Responsibility:</p>
<p className="ft_14 robot_regular d_lblack mt_10">While we implement security measures to protect your
login credentials, it is your responsibility to ensure the security of your digital
wallet and follow best practices for account protection.</p>
<p className="ft_18 roboto_medium mt_10">4. Cookies and Similar Technologies:</p>
<p className="ft_14 robot_regular d_lblack mt_10">Cookies are used to enhance user experience, analyze website traffic, and manage
user sessions. You can adjust your browser settings to refuse cookies, though this
may limit certain features of the Website.</p>
<p className="ft_18 roboto_medium mt_10">5. Information from third-party partners</p>
<p className="ft_14 robot_regular d_lblack mt_10">We may receive information about you and your activities from third-party partners.
For example:</p>

<p className="ft_14 robot_regular d_lblack mt_10">● A partner may share information when we jointly offer services.</p>
<p className="ft_14 robot_regular d_lblack mt_10">● Advertisers may provide information about your interactions or
experiences with their ads.</p>

<p className="ft_14 robot_regular d_lblack mt_10">This information is governed by the privacy policy of the respective third parties. As
we do not control their privacy practices, we encourage you to review their policies
before using their services.</p>
<p className="ft_14 robot_regular d_lblack mt_10">Our Platform may contain links to third party websites or apps. If you click on one of
these links, please note that each one will have its own privacy policy. We do not
control these websites/apps and are not responsible for those policies. When you
leave our Platform, we encourage you to read the privacy notice of every website you
visit.</p>
</div>
<div className="prodct_deatils">
<p className="ft_18 roboto_medium d_blue">How do we use this information?</p>
<p className="ft_14 robot_regular d_lblack mt_10">1. Providing Our Services</p>

<p className="ft_14 robot_regular d_lblack mt_10">We collect and use your information to:</p>
<p className="ft_14 robot_regular d_lblack mt_10">● Facilitate the listing, buying, and selling of products and services.</p>
<p className="ft_14 robot_regular d_lblack mt_10">● Enable secure and seamless communication between buyers and sellers.</p>
<p className="ft_14 robot_regular d_lblack mt_10">● Verify user identities to prevent fraud and unauthorized activities.</p>
<p className="ft_14 robot_regular d_lblack mt_10">● Process transactions securely through trusted payment partners.</p>
<p className="ft_14 robot_regular d_lblack mt_10">● Ensure a smooth and consistent experience across different devices.</p>
<p className="ft_14 robot_regular d_lblack mt_10">If you log in using your mobile number or email we use your details to authenticate
your identity and provide access to our platform.</p>
<p className="ft_14 robot_regular d_lblack mt_10">2. Improving User Experience</p>
<p className="ft_14 robot_regular d_lblack mt_10">We analyze user interactions to:</p>
<p className="ft_14 robot_regular d_lblack mt_10">● Personalize recommendations based on browsing history, interests, and
past activity.</p>
<p className="ft_14 robot_regular d_lblack mt_10">● Enhance search results and optimize ad rankings using AI-driven
algorithms.</p>
<p className="ft_14 robot_regular d_lblack mt_10">● Offer better customer support and user engagement features.</p>
<p className="ft_14 robot_regular d_lblack mt_10">● Improve platform security and reduce spam or unwanted content.</p>
<p className="ft_14 robot_regular d_lblack mt_10">● We may use machine learning models to analyze images, ad descriptions, and
pricing to better match buyers and sellers.</p>
<p className="ft_14 robot_regular d_lblack mt_10">3. Advertising &amp; Promotions</p>
<p className="ft_14 robot_regular d_lblack mt_10">To enhance your experience and keep our services free, we:</p>
<p className="ft_14 robot_regular d_lblack mt_10">● Show ads tailored to your preferences based on your activity.</p>
<p className="ft_14 robot_regular d_lblack mt_10">● Measure the effectiveness of advertisements and promotions.</p>
<p className="ft_14 robot_regular d_lblack mt_10">● Display relevant product recommendations.</p>
<p className="ft_14 robot_regular d_lblack mt_10">You have control over how your data is used for personalized advertising through
your account settings.</p>
<p className="ft_14 robot_regular d_lblack mt_10">4. Security &amp; Fraud Prevention</p>
<p className="ft_14 robot_regular d_lblack mt_10">We use advanced security measures to:</p>
<p className="ft_14 robot_regular d_lblack mt_10">● Detect and prevent fraudulent activities, scams, or suspicious behavior.</p>
<p className="ft_14 robot_regular d_lblack mt_10">● Identify fake listings and prevent unauthorized access.</p>
<p className="ft_14 robot_regular d_lblack mt_10">●Ensure safe transactions by monitoring activities and applying AI-based fraud
detection techniques.</p>
<p className="ft_14 robot_regular d_lblack mt_10">We also monitor user chats for fraud detection and content moderation, while
ensuring privacy through restricted access protocols.</p>
<p className="ft_14 robot_regular d_lblack mt_10">4. We may use your information to:</p>
<p className="ft_14 robot_regular d_lblack mt_10">● Comply with legal and regulatory obligations.</p>
<p className="ft_14 robot_regular d_lblack mt_10">● Respond to legal requests, court orders, or government inquiries.</p>
<p className="ft_14 robot_regular d_lblack mt_10">● Protect our platform, users, and partners from fraud, abuse, or security threats.</p>
</div>
<div className="prodct_deatils">
<p className="ft_18 roboto_medium d_blue">How is this information shared?</p>
<p className="ft_14 robot_regular d_lblack mt_10">We understand and value your privacy and We do not share any personal information of user
with any third party. We do not share or otherwise disclose any of the information to any third
party unless otherwise stated herein. We may have to share your personal data with the parties
set out below.</p>
<p className="ft_18 robot_medium d_black mt_20">1. Sharing with affiliates.</p>
<p className="ft_14 robot_regular d_lblack mt_10">We share information we have about you with our affiliates or other authorized third
parties who process the information for delivery of the services as described in this
policy.</p>

<p className="ft_18 robot_medium d_black mt_20">2. New owner.</p>
<p className="ft_14 robot_regular d_lblack mt_10">If the ownership or control of all or part of our Services or their assets changes, we may
transfer your information to the new owner. We may share your information if we have
your consent to do so. Please consider and read carefully before disclosing any data or
information.</p>


<p className="ft_18 robot_medium d_black mt_20">3. Sharing with Third-Party Partners and Customers.</p>
<p className="ft_14 robot_regular d_lblack mt_10">We work with third party companies who help us provide and improve our Services or
who use advertising or related products, which makes it possible to operate our
companies and provide free services to people around the world. This information is
utilized by the third party, in accordance with its privacy policy. Because we do not
control the privacy practices of these third parties, you should evaluate their practices
before deciding to use their services. Here are the types of third parties we may share
information with about you: We may share your personal information which includes
name, phone number and email address with select partners in the course of our business,
who have agreed to respect and protect your privacy in the same manner we do. These

partners may utilize your information to deliver services tailored to your preferences and
interests. You may contact our Grievance Officer if you do not wish to receive such
services.</p>
<p className="ft_18 robot_medium d_black mt_20">4. Advertising, Measurement and Analytics Services (Non-Personally Identifiable
  Information Only).</p>
<p className="ft_14 robot_regular d_lblack mt_10">We want our advertising to be as relevant and interesting as the other information you
find on our Services. With this in mind, we use all of the information we have about you
to show you relevant ads. We do not share information that personally identifies you
(personally identifiable information is information like name or email address that can by
itself be used to contact you or identifies who you are) with advertising, measurement, or
analytics partners unless you give us permission. We may provide these partners with
information about the reach and effectiveness of their advertising without providing
information that personally identifies you, or if we have aggregated the information so
that it does not personally identify you.
: In order to improve our Services, we will sometimes share your non-identifiable
information with analytics providers that help us analyze how people are using our
Platform/Service. We, share your information with them in non-identifiable form for
monitoring and reporting the effectiveness of the campaign delivery to our business
partners and for internal business analysis. For further details about our advertisers and
analytics providers</p>


<p className="ft_18 robot_medium d_black mt_20">5. Vendors, service providers and other partners.</p>
<p className="ft_14 robot_regular d_lblack mt_10">We transfer information to vendors, service providers, and other partners who globally
support our business, such as providing technical infrastructure services, analyzing how
our Services are used, measuring the effectiveness of ads and services, providing
customer service, facilitating payments, or conducting academic research and surveys.
We use third party service providers to help us deliver certain aspect of our Services for
example, cloud storage facilities.</p>
<p className="ft_14 robot_regular d_lblack mt_10">We conduct checks on our third-party service providers and require them to respect the
security of your personal data and to treat it in accordance with the law. We do not allow
them to use your personal data for their own purposes and only permit them to process
your personal data for specified purposes and in accordance with our instructions.</p>
</div>


<div className="prodct_deatils">
<p className="ft_18 roboto_medium d_blue">How long we store your information?</p>
<p className="ft_14 robot_regular d_lblack mt_10">We keep your information for no longer than necessary for the purposes for which the
information is processed. The length of time for which we retain information depends on the
purposes for which we collect and use it and/or as required to comply with applicable laws and
to establish, exercise or defend our legal rights.</p>
</div>


<div className="prodct_deatils">
<p className="ft_18 roboto_medium d_blue">Data Subject Rights</p>
<p className="ft_14 robot_regular d_lblack mt_10">You have the right to delete, access, update, correct errors in, and export for transfer any of your
personal information. We makes these data subject rights available to you regardless of which
country you reside in. Managing the subsequent use and disclosure of your personal information.
To exercise your privacy rights and choices, if you do not want us to process your data, then you
can use the Data Deletion Requests feature to issue a request for its removal. The option to
request data deletion is visible under Account &gt; Settings&gt; &quot;Deactivate account and delete my
data&quot;</p>

<p className="ft_14 robot_regular d_lblack mt_10">There may be situations where we cannot grant your request — for example, if you ask us to
delete your transaction data and We are legally obligated to keep a record of that transaction to
comply with law.</p>
</div>

<div className="prodct_deatils">
<p className="ft_18 roboto_medium d_blue">Children’s Privacy</p>
<p className="ft_14 robot_regular d_lblack mt_10">These Services do not address anyone under the age of 13. We do not knowingly collect
personally identifiable information from children under 13. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our
servers. If you are a parent or guardian and you are aware that your child has provided us with
personal information, please contact us so that we will be able to do necessary actions.</p>
</div>

<div className="prodct_deatils">
<p className="ft_18 roboto_medium d_blue">Risks and how we keep your personal information secure</p>
<p className="ft_14 robot_regular d_lblack mt_10">We are committed to taking reasonable efforts to secure the information that you choose to
provide us, and we use a variety of security technologies and procedures to help protect against
unauthorized access to or alteration, disclosure, or destruction of Personal Data. Unfortunately,
no transmission of Personal Data over the Internet can be guaranteed to be 100% secure.
Accordingly, and despite our efforts, we cannot guarantee or warrant the security of any
information you transmit to us, or to or from our online products or services. we have no
responsibility or liability for the security of information transmitted via the Internet.</p>
</div>


<div className="prodct_deatils">
<p className="ft_18 roboto_medium d_blue">Intellectual Property Rights</p>
<p className="ft_14 robot_regular d_lblack mt_10">All Content on the Website is protected by Copyright. We, own copyright and/or database rights
in the selection, coordination, arrangement and enhancement of such content, as well as in the
original content.</p>

<p className="ft_14 robot_regular d_lblack mt_10">Except as expressly set out in herein, nothing shall give you any right in respect of any
intellectual property owned by us. You shall not assert or attempt to obtain any such right and
you shall not use, copy, reproduce, modify, publish, transmit, participate in the transfer or sale
of, create derivative work from, or in any way exploit, Our Name, Trademarks, logos or other
proprietary marks, or any of the content of the site, in whole or in any part, except as provided
herein.</p>
<p className="ft_14 robot_regular d_lblack mt_10">Except as otherwise expressly permitted under copyright law, no copying, redistribution,
retransmission, publication or commercial exploitation of downloaded material will be permitted
without our express permission and that of the Copyright Owner. In the event of any permitted
Copying, redistribution or publication of copyright material, no changes in or deletion of author attribution, Trademark legend or Copyright Notice shall be made. You acknowledge that you do
not acquire any ownership right by downloading copyright material.</p>
</div>

<div className="prodct_deatils">
<p className="ft_18 roboto_medium d_blue">How will we notify you of changes to this policy?</p>
<p className="ft_14 robot_regular d_lblack mt_10">By using our services, you consent to the collection, use, and disclosure of information and to the
terms of this policy. We may time to time modify this policy, we will post the changes on this
page so that you are always aware of the latest version of Policy. If we make any material
changes, we will notify you before we make such changes to this policy and give you the
opportunity to review on the revised policy before continuing to use our Services.</p>
</div>

<div className="prodct_deatils">
<p className="ft_18 roboto_medium d_blue">Comments and Questions</p>
<p className="ft_14 robot_regular d_lblack mt_10">If you have any comments or questions about our privacy practices, please contact us at: hello@mchamp.com</p>
<p className="ft_14 robot_regular d_lblack mt_10">Date of Last Revision: 07/02/2025</p>

</div>







      </div>
   <div className='right_a'></div>
</div>
   </>
  )
}

export default Privacynpolicy
