import React, { useEffect, useState } from 'react'
import Ads from '../Ads/Index'
import { useQuery, useMutation } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';
import { BASE_URL } from '../../Apis/config'
import axios from 'axios';
import Search from '../Header/Search';
import share from '../../images/share.svg'
import inactive from '../../images/like_inactive.svg'
import location from '../../images/location_light.svg'
import { useLocation, useParams } from 'react-router-dom';
import { handleShare } from '../../Pages/Share'
import like from '../../images/liked.svg'
import Premiumad from '../Ads/Premiumad';
import Cookies from 'universal-cookie';
import { Pagination } from 'swiper/modules';
const cookies = new Cookies();
const ViewAll = () => {
  const { type } = useParams()
  const Currency = !cookies.get('Currency') ? "&#8358" : cookies.get('Currency');
  const CountryCode = !(cookies.get('Country')) ? "NG" : cookies.get('Country');
  const StateCode = cookies.get('Statevalue');
  const [LatestVal, SetLatestVal] = useState('')
  const [Res, SetRes] = useState(false)
  const [PremiumVal, SetPremiumVal] = useState('')
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(4); 
  const [OfLimit, SetOfLimit] = useState(0); 
  const itemsPerPage =4;
  const City = cookies.get('CityId');
  const id = cookies.get('Id');
  const url = type == 'Latest' ? 'latestposts' : 'premiumposts';
  const queryClient = useQueryClient();
  // get listing of all product 
  const fetchData = async (page) => {
    fetch(`${BASE_URL}/${url}?country_code=${CountryCode}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        limit:1000,
        offset: 0,
        orderBy: "DESC",
      })
    }).then((res) => {
      return res.json();
    }).then((data) => {
      SetRes(true)
      if(data.result.latestPosts.length>0){
        setTotalPages(data.result.latestPosts.length)
      }
      else if(data.result.premiumPosts.length>0){
        setTotalPages(data.result.premiumPosts.length)
      }
      SetLatestVal(data.result.latestPosts)
      SetPremiumVal(data.result.premiumPosts)
    }).catch((err) => {
      console.log(err.message);
    })
  };


  useEffect(() => {
    fetchData(currentPage);
  } , [currentPage]);

  // handel Pagination
  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
      SetOfLimit(itemsPerPage*(page-1))
    }
  };
  
  return (
    <>
      {Res && <div className="body_area">
        {type == 'Latest' && <p className="ft_18 mt_10 roboto_medium">
          Latest Listing
        </p>}
        {type == 'Premium' && <p className="ft_18 mt_10 roboto_medium">
          Premium Listing
        </p>}
        {type == 'Premium' && !(PremiumVal) && <p className="ft_14 mt_10 roboto_regular d_lblack">
          No result found
        </p>}
        {type == 'Latest' && !(LatestVal) && <p className="ft_14 mt_10 roboto_regular d_lblack">
          No result found
        </p>}
        {/* list*/}
        <div className="list_items mt_10">
          {type == 'Latest' && LatestVal && LatestVal.map((ele, i) => {
            return (
              <Ads pic={ele.images} title={ele.title} slug={ele.sub_category.slug}
                location={ele.location} price={ele.price} date={ele.created_at} featured={ele.featured} id={ele.id} uid={id} Currency={Currency}></Ads>
            )
          })
          }
          {type == 'Premium' && PremiumVal && PremiumVal.map((ele, i) => {
            return (
              <Ads pic={ele.images} title={ele.title} slug={ele.sub_category.slug}
                location={ele.location} price={ele.price} date={ele.created_at} featured={ele.featured} id={ele.id} uid={id} Currency={Currency}></Ads>
            )
          })
          }
        </div>
        {/* pagination  */}
        {/* <ul class="pagination">
        <li className={`${currentPage === 1?'':'active'}`} ><a onClick={() => handlePageChange(currentPage-1)}
              disabled={currentPage === 1}>{`<< Previous`}</a></li>
           <li className={`${currentPage === totalPages?'':'active'}`} onClick={() => handlePageChange(currentPage+1)}
            disabled={currentPage === totalPages}><a >{`Next >>`}</a></li>
          </ul> */}
      </div>}
    </>
  )
}
export default ViewAll
