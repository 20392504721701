import React from 'react'
import { Link } from 'react-router-dom'

const TnC = () => {
  return (
    <>
  <div className="body_area grid_main">
 <div className='left_a'>
  <div class="prodct_deatils">
   <p className="ft_18 roboto_medium mt_10">Last Updated on 05/02/2025</p>
   <p className="ft_14 robot_regular d_lblack mt_10">Please read these terms carefully before accessing or using this Website (Buy N Sell). By
accessing or using this Website, you acknowledge that you have read, understood, and agreed to
be bound by the following terms and conditions.

Throughout these Terms, &quot;you&quot; or &quot;your&quot; refers to any individual or entity that accesses or uses
the Website. If you do not agree to these Terms, you may not access or use the Website. Your
use of the Website also indicates acceptance of the Privacy Policy.

These Terms and Conditions constitute a legally binding agreement between you and mChamp
Entertainment Pvt. Ltd, having its registered office at I- 9, LGF, LAJPAT NAGAR-3, NEW
DELHI- 110024 India (hereinafter &quot;the Company&quot;). The Company operates the platform
providing online resources, including classified advertisements, listings, and related support via
Buy N Sell, its mobile application as well as any other media forms, mobile apps, or services
provided by the Company (&quot;Service&quot;).</p>
</div>
<div className="prodct_deatils">
<p className="ft_18 roboto_medium d_blue">1. Acceptance of Terms and Modifications</p>
<p className="ft_14 robot_regular d_lblack mt_10">By accessing, listing items, sharing information or using the Website or Services, you agree to
these Terms and any future modifications. The Company reserves the right to modify the
Terms at any time, with or without prior notice. Continuing to access or use the Services after
modifications means you accept the updated Terms. Non-compliance with the Terms will
result in the immediate discontinuation of your use of the Services.</p>
</div>

<div className="prodct_deatils">
<p className="ft_18 roboto_medium d_blue">2.Description of Services</p>
<p className="ft_18 roboto_medium mt_10">a. Intermediary Role:</p>
<p className="ft_14 robot_regular d_lblack mt_10">By accessing, listing items, sharing information or using the Website or Services, you agree to
these Terms and any future modifications. The Company reserves the right to modify the
Terms at any time, with or without prior notice. Continuing to access or use the Services after
modifications means you accept the updated Terms. Non-compliance with the Terms will
result in the immediate discontinuation of your use of the Services.</p>


<p className="ft_18 roboto_medium mt_10">b. Buy N Sell Platform:</p>
<p className="ft_14 robot_regular d_lblack mt_10">Users can list products for sale by providing images, descriptions,
and pricing details along with personal information such as Name, Email ID, Phone Number,
and Address. Buyers can browse listings, view product details, and purchase directly from
sellers after verifying the product.</p>

<p className="ft_18 roboto_medium mt_10">c. Third-Party Services:</p>
<p className="ft_14 robot_regular d_lblack mt_10">The Company collaborates with select third parties to enhance
user experience. Users engaging with such services must independently review their terms, as
the Company disclaims liability for third-party actions, services, or payments.</p>

<p className="ft_18 roboto_medium mt_10">d. Marketplace Role:</p>
<p className="ft_14 robot_regular d_lblack mt_10">The Company does not own or manage user listings and is not
responsible for product authenticity, condition, or any disputes. Sellers must ensure the
legality and accuracy of their listings.</p>

<p className="ft_18 roboto_medium mt_10">e. Content and Moderation:</p>
<p className="ft_14 robot_regular d_lblack mt_10">The Company does not control user-generated content but
may moderate posts for fraud prevention, compliance, or quality assurance. It reserves the
right to remove any content at its discretion.</p>

<p className="ft_18 roboto_medium mt_10">f. User Responsibilities:</p>
<p className="ft_14 robot_regular d_lblack mt_10">Users must own or have rights to the content they upload. By
posting content, users grant the Company a license to display and distribute it for platform
operations. Infringing content may be removed and repeat offenders may be banned.</p>

<p className="ft_18 roboto_medium mt_10">g. Third-Party Links:</p>
<p className="ft_14 robot_regular d_lblack mt_10">The platform may contain links to external websites, for which the
Company assumes no responsibility regarding accuracy, security, or reliability.</p>

<p className="ft_18 roboto_medium mt_10">h. Liability Disclaimer:</p>
<p className="ft_14 robot_regular d_lblack mt_10">The Company does not endorse or verify user content, opinions, or
advice. Users must exercise caution when interacting with listings and third parties.</p>
</div>



<div className="prodct_deatils">
<p className="ft_18 roboto_medium d_blue">3. Listing and Advertisement Guidelines</p>
<p className="ft_18 roboto_medium mt_10">Seller Terms</p>
<p className="ft_14 robot_regular d_lblack mt_10">Sellers can list their products by providing accurate details and images. Listing fees are as
follows:</p>
<p className="ft_18 roboto_medium mt_10">Normal Ad Listing</p>
<p className="ft_14 robot_regular d_lblack mt_10">0.1 USDT (subject to change)</p>

<p className="ft_18 roboto_medium mt_10">Premium Ad Listing</p>
<p className="ft_14 robot_regular d_lblack mt_10">0.2 USDT (subject to change)</p>

<p className="ft_18 roboto_medium mt_10">Buyer Terms</p>
<p className="ft_14 robot_regular d_lblack mt_10">Buyers can browse listings and contact sellers directly. To access a seller’s personal
information (Name, Email ID, Phone Number, and Address), buyers must pay 0.1 USDT
(subject to change). Buyers must verify products independently before making payments.</p>

</div>

<div className="prodct_deatils">
<p className="ft_18 roboto_medium d_blue">4. General Guidelines for Listing and Advertisements</p>
<p className="ft_18 roboto_medium mt_10">Submission Guidelines for Listings/Advertisements</p>
<p className="ft_18 roboto_medium mt_10">1. Rights and Permissions:</p>
<p className="ft_14 robot_regular d_lblack mt_10">Listers must ensure they have all necessary rights, authorizations, and permissions to list
items or advertisements, including rights to use photographs and other content. The
Company disclaims ownership or verification of user-submitted content, images, or
banners.</p>

<p className="ft_18 roboto_medium mt_10">2. Content Removal:</p>
<p className="ft_14 robot_regular d_lblack mt_10">The Company reserves the right to remove listings that violate these guidelines and may
request evidence of compliance. Accounts repeatedly violating these terms may be
terminated.</p>


<p className="ft_18 roboto_medium mt_10">3. Platform Usage:</p>
<p className="ft_14 robot_regular d_lblack mt_10">By submitting listings, Listers agree that content may be displayed, accessed,
downloaded, or modified as needed for platform purposes.</p>

<p className="ft_18 roboto_medium mt_10">Guidelines for Potential Buyers and Listers</p>
<p className="ft_18 roboto_medium mt_10">1. Independent Verification:</p>
<p className="ft_14 robot_regular d_lblack mt_10">Buyers are responsible for verifying the authenticity, ownership, and suitability of
listings, properties, or services at their own expense before making transactions.</p>

<p className="ft_18 roboto_medium mt_10">2. The Company’s Role:</p>
<p className="ft_14 robot_regular d_lblack mt_10">As an intermediary, the Company does not verify the content uploaded by Listers and is
not liable for disputes. However, complaints are addressed promptly.</p>

<p className="ft_18 roboto_medium mt_10">3. Due Diligence:</p>
<p className="ft_14 robot_regular d_lblack mt_10">Buyers should personally inspect items, verify documentation, and ensure compliance
with applicable laws before purchase.</p>

<p className="ft_18 roboto_medium mt_10">Category-Specific Guidelines</p>

<p className="ft_18 roboto_medium mt_10">1. Cars and Bikes:</p>
<p className="ft_14 robot_regular d_lblack mt_10">Verify ownership, documentation (RC, NoC, insurance), and roadworthiness. Listers
must facilitate RC transfers and other processes.</p>

<p className="ft_18 roboto_medium mt_10">2. Properties:</p>
<p className="ft_14 robot_regular d_lblack mt_10">Confirm legal documents (ownership deeds, approvals, RERA compliance, etc.) and
verify the property’s authenticity.</p>

<p className="ft_18 roboto_medium mt_10">3. Electronics and Appliances:</p>
<p className="ft_14 robot_regular d_lblack mt_10">Check specifications, warranty terms, and adherence to safety standards.</p>


<p className="ft_18 roboto_medium mt_10">4. Jobs:</p>
<p className="ft_14 robot_regular d_lblack mt_10">Verify employer credentials and ensure transparency in job listings.</p>

<p className="ft_18 roboto_medium mt_10">5. Furniture:</p>
<p className="ft_14 robot_regular d_lblack mt_10">Inspect design, materials, and compliance with quality standards.</p>

<p className="ft_18 roboto_medium mt_10">6. Fashion:</p>
<p className="ft_14 robot_regular d_lblack mt_10">Confirm product authenticity and review return policies.</p>
<p className="ft_18 roboto_medium mt_10">7. Books, Sports &amp; Hobbies:</p>
<p className="ft_14 robot_regular d_lblack mt_10">Verify product condition and compliance with intellectual property rights.</p>

<p className="ft_18 roboto_medium mt_10">8. Pets’ Food and Accessories:</p>
<p className="ft_14 robot_regular d_lblack mt_10">Ensure compliance with FSSAI guidelines and inspect the product&#39;s quality and
compatibility with pet needs.</p>

<p className="ft_18 roboto_medium mt_10">9. Miscellaneous Items:</p>
<p className="ft_14 robot_regular d_lblack mt_10">Listers and buyers must ensure compliance with applicable laws and conduct necessary
diligence.</p>

<p className="ft_18 roboto_medium mt_10">10. Home &amp; Lifestyle</p>
<p className="ft_14 robot_regular d_lblack mt_10">● Ensure items meet quality and safety standards (e.g., home décor, kitchenware, bedding)..<br></br>
● Verify materials, durability, and usability before purchase.</p>

<p className="ft_18 roboto_medium mt_10">11.Services (Movers, Packers, Repairs, etc.)</p>
<p className="ft_14 robot_regular d_lblack mt_10">● Service providers must be genuine and qualified for their respective services.<br></br>
● Verify legal compliance, insurance, and service guarantees, if applicable.<br></br>
● Customers should check reviews and verify credentials before hiring.</p>

<p className="ft_18 roboto_medium mt_10">12. Entertainment (Photography, Acting, Studios, Modeling, etc.)</p>
<p className="ft_14 robot_regular d_lblack mt_10">● Verify professional credentials and previous work history.<br></br>
● Ensure transparency in service pricing and deliverables.<br></br>
● Avoid misleading advertisements or false claims regarding services.s</p>
</div>


<div className="prodct_deatils">
<p className="ft_18 roboto_medium d_blue">5. User Conduct</p>
<p className="ft_14 robot_regular d_lblack mt_10">Users agree not to post, upload, share, or engage in activities on the Platform that:</p>
<p className="ft_18 roboto_medium mt_10">Prohibited Content and Activities</p>
<p className="ft_18 robot_medium d_black mt_20">1. Illegal and Fraudulent Content</p>
<p className="ft_14 robot_regular d_lblack mt_10">● Violate any applicable laws, regulations, or guidelines.<br></br>
● Contain misleading, fraudulent, or deceptive information.</p>

<p className="ft_18 robot_medium d_black mt_20">2. Intellectual Property Infringement</p>
<p className="ft_14 robot_regular d_lblack mt_10">● Infringe upon copyrights, trademarks, patents, or other intellectual property
rights unless explicitly authorized.</p>

<p className="ft_18 robot_medium d_black mt_20">3. Harmful, Abusive, or Offensive Content</p>
<p className="ft_14 robot_regular d_lblack mt_10">● Include obscene, defamatory, abusive, or objectionable material.<br></br>
● Promote violence, discrimination, or illegal activities.<br></br>
● Invade privacy or harass other users.
</p>

<p className="ft_18 robot_medium d_black mt_20">4. Unauthorized Advertising and Spam</p>
<p className="ft_14 robot_regular d_lblack mt_10">● Engage in unsolicited promotions, pyramid schemes, or multi-level marketing
(MLM).<br></br>
● Post bulk advertisements or misleading commercial offers.
</p>



<p className="ft_18 robot_medium d_black mt_20">5. Privacy Violations and Impersonation</p>
<p className="ft_14 robot_regular d_lblack mt_10">● Share or collect personal data of users without their consent.<br></br>
● Impersonate any individual, entity, or organization.
</p>

<p className="ft_18 robot_medium d_black mt_20">6. Security Risks and Malicious Activity</p>
<p className="ft_14 robot_regular d_lblack mt_10">● Post content containing viruses, malware, or any harmful software.<br></br>
● Use automated tools to scrape data, manipulate listings, or disrupt platform
operations.
</p>

<p className="ft_18 robot_medium d_black mt_20">7. Threats to Public Safety and National Integrity</p>
<p className="ft_14 robot_regular d_lblack mt_10">● Engage in activities that threaten the unity, integrity, or security of any country
or state.
</p>

<p className="ft_18 robot_medium d_black mt_20">8. Disruption of Platform Operations</p>
<p className="ft_14 robot_regular d_lblack mt_10">● Exceed reasonable usage limits, disrupt normal functioning, or interfere with
other users&#39; experiences.
</p>


<p className="ft_18 roboto_medium mt_10">Enforcement and Consequences</p>

<p className="ft_14 robot_regular d_lblack mt_10">● The Company reserves the right to remove any content that violates these terms.<br></br>
● Accounts found in violation may be suspended or permanently terminated without prior notice.<br></br>
● Users engaging in unlawful activities may be subject to legal action in accordance with applicable laws.<br></br>
By using the Platform, you acknowledge and agree to abide by these rules to ensure a safe and
compliant marketplace.
</p>
</div>


<div className="prodct_deatils">
<p className="ft_18 roboto_medium d_blue">6. Paid Services</p>
<p className="ft_18 roboto_medium mt_10">Paid Services Overview</p>

<p className="ft_14 robot_regular d_lblack mt_10">You may be charged a fee for certain features on the Platform (&quot;Paid Services&quot;). By using
these features, you agree to the terms outlined herein. These terms apply to both Paid and free
Services.</p>

<p className="ft_18 robot_medium d_black mt_20">1. Premium Ads:</p>
<p className="ft_14 robot_regular d_lblack mt_10">Highlight your ad in a designated area of the Platform for increased
visibility. We don’t guarantee a specific increase in responses. You can purchase individual
or bulk packages, and ads will remain featured for the duration of your purchase or 30 days
from posting.</p>
<p className="ft_14 robot_regular d_lblack mt_10">Payments are non-refundable, except in cases of technical failures where unused services
may be credited.</p>

</div>



<div className="prodct_deatils">
<p className="ft_18 roboto_medium d_blue">7. Access to the Service</p>
<p className="ft_14 robot_regular d_lblack mt_10">Buy N Sell grants users a limited, revocable, and non-exclusive license to access and use the
platform for personal and non-commercial purposes. This license does not include:</p>

<p className="ft_18 roboto_medium mt_10">Use by Posting Agents:</p>
<p className="ft_14 robot_regular d_lblack mt_10">Posting Agents or third parties may not access or use the platform unless
explicitly authorized by Buy N Sell.</p>
<p className="ft_18 roboto_medium mt_10">Automated Data Collection:</p>
<p className="ft_14 robot_regular d_lblack mt_10">● Users may not collect, copy, scrape, or use data mining tools to extract
information from the platform without prior written consent.<br>
</br>
● General-purpose search engines and public archives may access the platform
under specific conditions, such as using a stable IP address, an identifiable
agent, and compliance with robots.txt.</p>

<p className="ft_18 roboto_medium mt_10">Linking and Displaying Content:</p>
<p className="ft_14 robot_regular d_lblack mt_10">● Users may display or hyperlink to individual listings for non-commercial or
news purposes, subject to Buy N Sell’s guidelines.</p>
<p className="ft_18 roboto_medium mt_10">Enforcement and Termination</p>

<p className="ft_14 robot_regular d_lblack mt_10">● Any unauthorized use of the platform immediately terminates this license.<br></br>● Buy N Sell reserves the right to restrict access or take legal action for non-compliance.<br></br>● Commercial use of the platform requires prior written authorization from Buy N Sell.</p>
</div>


<div className="prodct_deatils">
<p className="ft_18 roboto_medium d_blue">8. Intellectual Property Right</p>
<p className="ft_18 roboto_medium mt_10">1. Ownership of Materials</p>

<p className="ft_14 robot_regular d_lblack mt_10">● All materials on the Buy N Sell platform, including text, software, graphics, logos,
trademarks, and other content (collectively, “Materials”), are either owned by or licensed
to Buy N Sell and are protected under applicable copyright and trademark laws.<br></br>● Any third-party trademarks displayed on the platform are used under license and may
not be modified, copied, or reverse-engineered.</p>
<p className="ft_18 roboto_medium mt_10">2. Usage Restrictions</p>
<p className="ft_14 robot_regular d_lblack mt_10">● Materials on the platform are provided for informational and personal use only.<br></br>
● You may not use, copy, reproduce, distribute, or exploit any content without prior written consent from the respective owners.<br></br>
● If you use platform materials for personal purposes, you must retain all copyright and
ownership notices.</p>


<p className="ft_18 roboto_medium mt_10">3. Prohibited Actions</p>
<p className="ft_18 robot_medium d_black mt_20">Users agree not to:</p>
<p className="ft_14 robot_regular d_lblack mt_10">● Interfere with the platform’s security features or attempt unauthorized access.<br>
</br>● Use the platform or its content for commercial purposes without written
authorization.<br></br>● Copy, distribute, modify, or reverse-engineer any part of the platform or its
content.</p>

<p className="ft_18 roboto_medium mt_10">4. Protection of Content</p>
<p className="ft_14 robot_regular d_lblack mt_10">● All content on Buy N Sell is legally protected, and unauthorized reproduction,
distribution, or modification is strictly prohibited.<br></br>
● Buy N Sell is not liable for any copyright or trademark infringement committed by users
or third parties.</p>
<p className="ft_18 roboto_medium mt_10">5. Reporting Infringements</p>
<p className="ft_14 robot_regular d_lblack mt_10">● If you believe that content on Buy N Sell infringes your intellectual property rights, you
may report it by submitting a Report Form.</p>
</div>



<div className="prodct_deatils">
<p className="ft_18 roboto_medium d_blue">9. User Submissions</p>
<p className="ft_18 robot_medium d_black mt_20">1. Exposure to Varied Content:</p>

<p className="ft_14 robot_regular d_lblack mt_10">● You acknowledge that the Buy N Sell platform hosts content from various users
and sources.<br></br>
● Buy N Sell does not guarantee the accuracy, reliability, usefulness, safety, or
intellectual property compliance of any content posted on the platform.<br></br>
● You assume full responsibility and liability for your use of such content.</p>

<p className="ft_18 robot_medium d_black mt_20">2. Potential for Objectionable Content:</p>

<p className="ft_14 robot_regular d_lblack mt_10">● You understand that some content on the platform may be inaccurate, misleading,
offensive, indecent, defamatory, or objectionable.<br></br>
● By using the platform, you waive any legal or equitable rights or remedies against
Buy N Sell regarding such content.<br></br>
● If you come across objectionable content, you may report it to Buy N Sell for
review and possible removal.</p>
</div>

<div className="prodct_deatils">
<p className="ft_18 roboto_medium d_blue">10. Indemnity</p>
<p className="ft_14 robot_regular d_lblack mt_10">You agree to indemnify, defend, and hold harmless Buy N Sell, its affiliates, employees,
directors, and partners from any claims, damages, liabilities, costs, expenses, or legal fees
arising from:</p>

<p className="ft_18 roboto_medium mt_10">1. Platform Usage:</p>
<p className="ft_14 robot_regular d_lblack mt_10">Your use of the Buy N Sell platform and services.</p>

<p className="ft_18 roboto_medium mt_10">2. Violation of Terms:</p>
<p className="ft_14 robot_regular d_lblack mt_10">Any breach of these Terms and Conditions.</p>

<p className="ft_18 roboto_medium mt_10">3. Third-Party Rights</p>
<p className="ft_14 robot_regular d_lblack mt_10">Infringement of intellectual property rights (e.g., copyrights,
  trademarks, patents) or any legal rights of third parties.</p>
  <p className="ft_18 roboto_medium mt_10">4. Content Damage:</p>
<p className="ft_14 robot_regular d_lblack mt_10">Claims that any content posted by you caused harm, loss, or legal
disputes.</p>

<p className="ft_18 roboto_medium mt_10">5. Lack of Consent/Compliance:</p>
<p className="ft_14 robot_regular d_lblack mt_10">Failure to obtain necessary approvals, licenses, or
comply with applicable laws related to your products, services, or transactions.</p>

<p className="ft_18 roboto_medium mt_10">6. Third-Party Terms:</p>
<p className="ft_14 robot_regular d_lblack mt_10">Non-compliance with third-party agreements, terms, or policies
affecting your use of the platform.</p>

<p className="ft_14 robot_regular d_lblack mt_10">This indemnification obligation will survive even after termination or modification of
these Terms.</p>
</div>


<div className="prodct_deatils">
<p className="ft_18 roboto_medium d_blue">11. Prohibition of Unsolicited Communications</p>
<p className="ft_14 robot_regular d_lblack mt_10">● You agree not to send unsolicited advertisements, spam, or unauthorized communications
through Buy N Sell’s platform, email addresses, or messaging systems.<br></br>
● Buy N Sell reserves the right to monitor email and message exchanges to detect and
prevent spam, fraud, or scams between users.<br></br>● All communications made via the platform must comply with these Terms and applicable
laws.<br></br>● Unauthorized use of Buy N Sell’s communication systems violates these Terms and may
result in account suspension, legal action, or other penalties.</p>
</div>


<div className="prodct_deatils">
<p className="ft_18 roboto_medium d_blue">12. Interactions with Organizations and Individuals</p>
<p className="ft_14 robot_regular d_lblack mt_10">a) You acknowledge that Buy N Sell is not liable for your interactions with other users,
organizations, or third parties on the platform or through its services. This includes, but is
not limited to:</p>
<p className="ft_14 robot_regular d_lblack mt_10">● Payments made to sellers or service providers.<br></br>
● Product delivery and fulfillment of transactions.<br></br>
● Quality, authenticity, or condition of listed products.<br></br>
● Any associated terms, warranties, or representations made by third parties.</p>

<p className="ft_14 robot_regular d_lblack mt_10">b) All dealings and transactions are solely between you and the other party involved.</p>
<p className="ft_14 robot_regular d_lblack mt_10">c) Buy N Sell is not responsible for any loss, damage, fraud, misrepresentation, or
dispute arising from such interactions.</p>
<p className="ft_14 robot_regular d_lblack mt_10">d) Buy N Sell has no obligation to mediate or intervene in disputes between users.</p>
<p className="ft_14 robot_regular d_lblack mt_10">e) By using the platform, you release Buy N Sell, its affiliates, officers, and agents from
any claims, damages, or liabilities related to such disputes.</p>
</div>


<div className="prodct_deatils">
<p className="ft_18 roboto_medium d_blue">13. Limitation And Termination of Service</p>
<p className="ft_14 robot_regular d_lblack mt_10">a) You acknowledge that Buy N Sell may impose limits on the use of its services,
including but not limited to:</p>
<p className="ft_14 robot_regular d_lblack mt_10">● The duration for which content is retained.<br></br>
● The number, size, or frequency of posts.<br></br>
● The rate of access to the platform.<br></br>
</p>
<p className="ft_14 robot_regular d_lblack mt_10">b) If multiple accounts are linked to the same mobile number, email, or social media
account, Buy N Sell reserves the right to remove or restrict access to duplicate accounts.</p>


<p className="ft_14 robot_regular d_lblack mt_10">c) Buy N Sell is not responsible for the deletion, loss, or failure to store any content
uploaded by users.</p>


<p className="ft_14 robot_regular d_lblack mt_10">d) Buy N Sell may modify, limit, suspend, or discontinue the Service at any time, with
or without notice, and shall not be liable for any resulting losses or inconveniences.</p>
<p className="ft_14 robot_regular d_lblack mt_10">e) Buy N Sell reserves the right to:</p>
<p className="ft_14 robot_regular d_lblack mt_10">● Delete, suspend, or deactivate your account.<br></br>
● Block access to the platform.<br></br>
● Terminate your use of the Service, with or without notice, if you violate these
Terms.<br></br>
</p>
<p className="ft_14 robot_regular d_lblack mt_10">f) Buy N Sell is not liable for any consequences arising from such actions.</p>
</div>



<div className="prodct_deatils">
<p className="ft_18 roboto_medium d_blue">14.	User Eligibility</p>
<p className="ft_14 robot_regular d_lblack mt_10">The Services are intended for users who are at least 18 years of age. By using the Website, you confirm that you are at least 18 years old and agree to these Terms and the Privacy Policy. The Company does not permit individuals under 18 to use or register for the Services.
</p>
</div>



<div className="prodct_deatils">
<p className="ft_18 roboto_medium d_blue">15. Geographical Restrictions</p>
<p className="ft_14 robot_regular d_lblack mt_10">You are responsible for ensuring compliance with all applicable laws when accessing or using
the Services, particularly in jurisdictions where distribution or use of the Services may be
illegal. The Company reserves the right to delete or restrict access to your account without
notice if it determines that you are using the Services in a prohibited jurisdiction.</p>
</div>


<div className="prodct_deatils">
<p className="ft_18 roboto_medium d_blue">16. Third-Party Websites</p>
<p className="ft_14 robot_regular d_lblack mt_10">The content of this Website may be accessible via third-party websites or platforms (&quot;Third-
Party Websites&quot;). Your use of the Website through these platforms remains subject to this
Agreement and applicable third-party terms.</p>
</div>

<div className="prodct_deatils">
<p className="ft_18 roboto_medium d_blue">17. Privacy Policy</p>
<p className="ft_14 robot_regular d_lblack mt_10">Your use of the Website is also governed by our Privacy Policy, located at
<Link to={'/Terms'}>https://bns.mchamp.xyz/privacypolicy</Link> . By using the Services, you consent to the collection,
use, and disclosure of your personal data as described in the Privacy Policy.</p>
</div>


<div className="prodct_deatils">
<p className="ft_18 roboto_medium d_blue">18. User Warrants</p>
<p className="ft_14 robot_regular d_lblack mt_10">By using the Website, you confirm that you are fully able and competent to understand and
accept this terms as a binding contract and to comply with these Terms and conditions.</p>

<p className="ft_14 robot_regular d_lblack mt_10">a) You are prohibited from acquiring, copying, or monitoring any portion of the
Content and/or the Website in any manner to: (a) reproduce or circumvent the
navigational structure or presentation of the Content and/or Website; or (b) obtain or
attempt to obtain any materials, documents, or information through any means not
specifically made available through the Website and/or Content.</p>

<p className="ft_14 robot_regular d_lblack mt_10">b) You are prohibited from altering, deleting, or attempting to alter or delete any
attributions, legal or other notices, proprietary designations, labels of the origin or
source of software, or other material on the Content and/or the Website.</p>

<p className="ft_14 robot_regular d_lblack mt_10">c)You shall not host, display, upload, modify, publish, transmit, store, update and / or share any information that:</p>
<p className="ft_14 robot_regular d_lblack mt_10">i. Content that is harmful to children.<br></br>ii.Content that violates any law in force.<br></br>
iii.Material that infringes any patent, trademark, copyright, or other proprietary rights.<br></br>
iv.harm anyone or to cause offence to harass any person or interfere with the ability of others to enjoy using the Services.<br></br>
v.Material that belongs to any other person and to which you do not have any lawful right including, without limitation, by using our Website to impersonate any person or entity, or otherwise misrepresent your affiliation with a person, entity, our Website.<br></br>
vi.Content that is harmful, defamatory, obscene, pornographic, libelous, paedophilic, invasive of other person's privacy, insulting or harassing on the basis of gender, hateful, racially or ethnically objectionable, disparaging, related to or encouraging money laundering or gambling, or otherwise harmful in any manner inconsistent with or contrary to the laws in force.<br></br>
vii.Information that deceiving or misleading about the origin of the message.<br></br>
viii.Content containing viruses or any other computer code, files, or programs designed to interrupt, damage, or limit the functionality of any computer device.<br></br>
ix.Information that threatens the unity, integrity, defence, security, or sovereignty of the state, friendly relations with foreign states, or public order, causes incitement to the commission of any cognizable offense, prevents investigation of any offense, or is insulting to any other nation.
</p>
<p className="ft_14 robot_regular d_lblack mt_10">d)Users shall not violate or attempt to violate the integrity, defence or security of the Content and / or the Website, and our networks; User shall not remove or amend any proprietary notices or other ownership information from the Website.
</p>
<p className="ft_14 robot_regular d_lblack mt_10">e)Users are prohibited from attempting to decompile, reverse engineer, disassemble, or hack the Content and/or the Website or to defeat or overcome any of the encryption technologies or security measures or data transmitted, processed or stored by the Website. Furthermore, users are not allowed to create any derivative works of the Website and/or the Content.</p>
<p className="ft_14 robot_regular d_lblack mt_10">f)Users are strictly prohibited from copying, transmitting, or making commercial use in any manner of any part of the Website, including the Website Material, and/or the Content. User shall not use the Website for any commercial use, or to advantage third parties or facilitate the sending of any advertisement or spam.</p>

<p className="ft_14 robot_regular d_lblack mt_10">g)User shall not disobey any requirements or regulations of any networks that may be connected to the Website.</p>
<p className="ft_14 robot_regular d_lblack mt_10">h)User shall not use, or encourage, promote, facilitate or instruct others to use the Website for any illegal, harmful or offensive use:</p>
<p className="ft_14 robot_regular d_lblack mt_10">i) Users are not permitted to make any negative, denigrating, or defamatory statements or comments about the Content and/or the Website, us, or any brand name or domain name used by us. Additionally, users are prohibited from engaging in any conduct or action that might tarnish our image or that of any other users of the Content and/or the Website.
 
 The company reserves the right to exclude the User and their devices immediately from the services without stating the specific reason in the event of inadmissible usage behavior.
  
 Website may contain links to other Internet sites owned and operated by third parties. Users' use of each of those sites is subject to the conditions, if any, posted by the sites. The Company does not exercise control over any Internet sites apart from the Website and cannot be held responsible for any content residing in any third-party Internet site. The Company's inclusion of third-party content or links to third-party Internet sites is not an endorsement by the Company of such third-party Internet site.
  
 Users' correspondence, transactions/offers or related activities with third parties, including payment providers and verification service providers, are solely between the User and that third party. Users' correspondence, transactions and usage of the services/offers of such third party shall be subject to the terms and conditions, policies and other service terms adopted/implemented by such third party, and the User shall be solely responsible for reviewing the same prior to transacting or availing of the services/offers of such third party. User agrees that the Company will not be responsible or liable for any loss or damage of any sort incurred as a result of any such transactions/offers with third parties. Any questions, complaints, or claims related to any third-party product or service should be directed to the appropriate vendor.
  
 The Website may also be accessible on Third-Party Platforms. However, we cannot guarantee that their objectives, policies, terms, or content guidelines align with ours. We do not confirm authorization for the Website's use on these Third-Party Platforms. Therefore, we disclaim responsibility for any representations made by them and do not accept liability for their accuracy. Users should independently review the terms and policies of Third-Party Platforms before use. We have no knowledge of or involvement in their terms and policies and disclaim liability for any loss or damage resulting from their use. We do not endorse Third-Party platforms in any way.
  
 </p>

</div>
    </div>
<div className='right_a'></div>
</div>
</>
  )
}

export default TnC
