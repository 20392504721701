import React, { memo, useEffect, useState } from 'react'
import Categories from '../Categories'
import Ads from '../Ads/Index'
import arrow from '../../images/arrow.svg'
import add from '../../images/add.svg'
import { useQuery, useMutation } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';
import { BASE_URL } from '../../Apis/config'
import axios from 'axios';
import Premiumad from '../Ads/Premiumad'
import { Link, Links, Navigate, useNavigate } from 'react-router-dom'
import search from '../../images/search.svg'
import Search from '../Header/Search';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
const HeroSec = (props) => {
const CountryCode =!(cookies.get('Country'))?props.Country:cookies.get('Country');
const Currency =!(cookies.get('Currency'))?props.CurrencySymbol:cookies.get('Currency');
const CountryCodeName=!(cookies.get('CountryName'))?props.CountryName:cookies.get('CountryName');
const StateCode = cookies.get('Statevalue');
const City = cookies.get('CityId');
  const StatVal= cookies.get('Statevalue');
  const[FilteredData,SetFilteredData]=useState('')
  const[Category,SetCategory]=useState('')
  const[Premium,SetPremium]=useState('')
  const[Latest,SetLatest]=useState('')
  const CategoriesApi = async () => {
    const { data } = await axios.get(BASE_URL, {
      params: {country_code:CountryCode,
        state_id:!(StateCode)?'':StateCode,
        city_id:!(City)?'':City
      },        
    });
    return data;
  };
  // useEffect(() => {
  //      fetch(`${BASE_URL}/?country_code=${CountryCode}&state_id=${StateCode}&city_id=${City}`, {
  //        method: 'GET',
  //      }).then((res) => {
  //        return res.json();
  //      }).then((data) => {
  //       SetCategory(data.result.categories)
  //       SetLatest(data.result.latestPosts) 
  //       SetPremium(data.result.premiumPosts)
  //        console.log('data11',data.result)
  //        SetFilteredData(data.result.posts.data)
  //      }).catch((err) => {
  //        console.log(err.message);
  //      })
  //    },);
  const id=props.id
  const queryClient = useQueryClient();
  const { data, error, isLoading } = useQuery({
    queryKey: ["Data"],
    queryFn: CategoriesApi
  });
  const nav=useNavigate()
const res=()=>{
  nav('/AddProduct')
}
  queryClient.setQueryData(['filteredData'], data);
    if(data){
      cookies.set('Categories',data.result.categories, { path: '/' });
      queryClient.setQueryData(['Related'], data.result.latestPosts);
      // queryClient.setQueryData(['Categories'],data.result.categories);
      queryClient.setQueryData(['Premium'], data.result.premiumPosts);

    }
  return (
    <>
     <div className="body_area">
      <Search></Search>
<div className="category_s mt_20">
{data&& data.result.categories.map((ele, i) => {
                  return (
 <Categories key={i} pic={ele.picture} count={ele.posts_count} name= {ele.name} slug={ele.slug} id={ele.id}></Categories>
)
})
} 
</div>
<div className="btm_fixed cont_user mt_10 d_flex d_none btm_home" >
  <button className=" btn_full pr_btn roboto_regular ft_14 " onClick={res}><img src={add} alt=" " className="btn_img"/>Post an AD</button>
</div>
<div className="d_flex mt_20">
  <p className="ft_18 roboto_medium">Fresh recommendations</p>
  <Link  to={'/ViewAll/Premium'} className="ft_14 location_btn  roboto_regular d_blue">
    View all
    <img src={arrow} alt="arrow" />
  </Link>
</div>
<div className="cards mt_10">
{data&& data.result.premiumPosts.map((ele, i) => {             
 return (
  <Premiumad  key={i} pic={ele.images} title={ele.title} slug={ele.sub_category.slug}
  location={ele.location} price={ele.price} date={ele.created_at} featured={ele.featured}id={ele.id} uid={id}Currency={Currency}></Premiumad>
)
})
} 
{/* {FilteredData&&FilteredData.map((ele, i) => {             
 return (
  <Premiumad pic={ele.images} title={ele.title} slug={ele.sub_category.slug}
  location={ele.location} price={ele.price} date={ele.expire_date} featured={ele.featured}id={ele.id} uid={id}></Premiumad>
)
})
} */}
</div>
<div className="d_flex mt_20">
  <p className="ft_18 roboto_medium">Latest Listings</p>
  <Link to={'/ViewAll/Latest'}  className="ft_14 location_btn  roboto_regular d_blue">
    View all
    <img src={arrow} alt="arrow" />
  </Link>
</div>
<div className="list_items mt_10">
{data&&data.result.latestPosts.map((ele, i) => {             
 return (
  <Ads key={i} pic={ele.images} title={ele.title} slug={ele.sub_category.slug}
  location={ele.location} price={ele.price} date={ele.created_at} featured={ele.featured} id={ele.id}uid={id}Currency={Currency}></Ads>
)
})
}

</div>



     </div>

    </>
  )

}

export default memo(HeroSec);
