import React, { useState } from 'react'
import { useQuery, useMutation } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';
import { BASE_URL } from '../../../Apis/config'
import axios from 'axios';
import Categories from '../../Categories';
import Ads from '../../Ads/Index';
import { useEffect } from 'react';
import Empty from '../../../Pages/Empty';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
const FavouriteProduct = () => {
  const uid = cookies.get('Id');
  const Currency = !cookies.get('Currency')?"&#8358":cookies.get('Currency');
const [data,setData]=useState([])
//  Get favriout list
  useEffect(() => {
    fetch(`${BASE_URL}/getwishlist`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            user_id : uid,
      })
    }).then((res)=>{
        return res.json();
    }).then((data)=>{
        console.log('jhfjf',data); 
        setData(data.result)
    }).catch((err)=>{
        console.log(err.message);   
    })
  },[]);
    return (
   <>
   <div className="body_area">
    <p class="ft_18 roboto_medium mt_10">My Favourites</p>
    <div className='list_items mt_10'>
    {data&& data.map((ele, i) => {
                    return (
                      <Ads pic={ele.images} title={ele.title} slug={ele.slug}
    location={ele.location} price={ele.price} date={ele.created_date} featured={'1'} id={ele.post_id}Currency={Currency} pid={ele.id} status={'liked'}></Ads>
  )
  })
  } 
  {!data&&<Empty></Empty>}
</div>
</div>
   </>
  )
}

export default FavouriteProduct
